@import './Color.less';

@font-family-primary: Poppins, sans-serif;
@font-family-secondary: Roboto;

.font-size-1 {
    font-size: 10px;
    line-height: 16px;
}

.font-size-2 {
    font-size: 12px;
    line-height: 20px;
}

.font-size-3 {
    font-size: 14px;
    line-height: 22px;
}

.font-size-4 {
    font-size: 16px;
    line-height: 24px;
}

.font-size-5 {
    font-size: 20px;
    line-height: 28px;
}

.font-size-6 {
    font-size: 24px;
    line-height: 32px;
}

.font-size-7 {
    font-size: 30px;
    line-height: 38px;
}

.font-size-8 {
    font-size: 38px;
    line-height: 46px;
}

.font-size-9 {
    font-size: 46px;
    line-height: 54px;
}

.font-size-10 {
    font-size: 56px;
    line-height: 64px;
}

.body {
    .font-size-3();
    font-weight: 300;
    font-family: @font-family-primary;
    color: @text-primary;
}

.body-strong {
    .font-size-3();
    font-weight: 600;
    font-family: @font-family-primary;
    color: @text-primary;
}

.body-lg {
    .font-size-4();
    font-weight: 300;
    color: @text-primary;
}

.subtitle {
    .font-size-3();
    font-weight: 500;
    color: @gray-9;
}

.subtitle-lg {
    .font-size-4();
    font-weight: 500;
    color: @gray-9;
}

.heading {
    .font-size-5();
    font-family: @font-family-secondary;
    font-weight: 500;
    color: @gray-9;
}

.heading-lg {
    .font-size-6();
    font-family: @font-family-secondary;
    font-weight: 500;
    color: @gray-9;
}

.heading-xl {
    .font-size-7();
    font-family: @font-family-secondary;
    font-weight: 500;
    color: @gray-9;
}

.heading-2xl {
    .font-size-8();
    font-family: @font-family-secondary;
    font-weight: 500;
    color: @gray-9;
}

.heading-3xl {
    .font-size-9();
    font-family: @font-family-secondary;
    font-weight: 500;
    color: @gray-9;
}

.heading-4xl {
    .font-size-10();
    font-family: @font-family-secondary;
    font-weight: 500;
    color: @gray-9;
}

.overline {
    .font-size-1();
    text-transform: uppercase;
}

.footer-heading {
    .font-size-5();
    text-transform: uppercase;
}

.caption {
    .font-size-2();
}

.button-sm {
    .font-size-2();
    text-transform: capitalize;
}

.button-md {
    .font-size-3();
    text-transform: capitalize;
}

.button-lg {
    .font-size-4();
    text-transform: capitalize;
}

.label {
    .font-size-2();
    font-family: @font-family-secondary;
    text-transform: capitalize;
}

.input-text {
    .font-size-3();
    font-family: @font-family-secondary;
    font-weight: 300;
}

.help-text {
    .font-size-2();
    font-family: @font-family-secondary;
}

.tooltip {
    font-size: var(--text-1);
}