@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import './Color.less';
@import './CircularTypography.less';

.ant-table-row {
  &.not-released {
    color: @gray-6;
  }
  .anticon.anticon-check-circle {
    color: @secondary-color;
    text-align: center;
  }
}