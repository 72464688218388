code {
  display: inline-block;
  margin: 0 0.2em;
  padding-inline: 0.4em;
  padding-block: 0.2em 0.1em;
  font-size: 85%;
  font-family: sfmono-regular, Consolas, liberation mono, Menlo, Courier,
    monospace;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}

p {
  margin-bottom: 0;
}

.changelog-dropdown {
  padding: 10px;

  label {
    margin-right: 10px;
    font-weight: bold;
  }

  .ant-select {
    min-width: 150px;
  }
}
